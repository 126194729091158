<template>
  <q-footer class="Footer text-white bg-dark" aria-label="Bottenmeny">
    <div class="q-px-md q-py-xl q-mx-auto" style="max-width: 1360px">
      <div class="row">
        <div class="col col-12 col-md-4 col-lg-3">
          <h5 class="q-mb-sm">Kapclear</h5>
          <div>C/o iOffice <br />Vasagatan 12, 6 tr<br />111 20 Stockholm</div>
          <div class="q-mt-sm">
            <a href="https://www.kaptena.se/cookies-gdpr/" class="text-white">Cookies- och integritetspolicy</a>
          </div>
        </div>
        <div class="col col-12 col-md-4 col-lg-3">
          <h5 class="q-mb-sm">Kontakt</h5>
          <div>
            <a class="text-white" :href="'mailto:' + contacts.kapclearEmail">{{ contacts.kapclearEmail }}</a>
            <br />
            <a class="text-white" :href="'tel:' + contacts.kapclearPhone"> {{ contacts.kapclearPhoneDisplay }}</a>
          </div>
        </div>
        <div class="col col-12 col-md-4 col-lg-6 text-right">
          <h5 class="q-mb-sm">Följ oss</h5>
          <a href="https://se.linkedin.com/company/kaptena-sverige-ab" target="_blank">
            <q-icon name="svguse:/svg-icons.svg#icon-linkedin" size="sm"></q-icon>
          </a>
          <a href="https://www.facebook.com/kaptenaab/" target="_blank">
            <q-icon name="svguse:/svg-icons.svg#icon-facebook" size="sm"></q-icon>
          </a>
          <a href="https://twitter.com/kaptenaab" target="_blank">
            <q-icon name="svguse:/svg-icons.svg#icon-twitter" size="sm"></q-icon>
          </a>
        </div>
      </div>
    </div>
  </q-footer>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { KaptenaContact } from '@/models';

@Component
export default class Footer extends Vue {
  get contacts() {
    return KaptenaContact;
  }
}
</script>
