<template>
  <div class="OwnerSummaryPDF">
    <h1>Aktieägaröversikt</h1>
    <table class="OwnersSummary">
      <thead>
        <tr>
          <th class="text-left">Ägare</th>
          <th class="text-right">Antal</th>
          <th class="text-right">
            <span class="slutref">
              Kapitalandel
              <sup><a href="#slutnot-1">1</a></sup>
            </span>
          </th>
          <th class="text-right">Antal röster</th>
          <th class="text-right">Röstandel</th>
          <th v-if="canEdit"></th>
        </tr>
      </thead>
      <tbody v-for="(record, i) in owners" :key="`tbody-${i}`">
        <tr class="owner-row text-bold">
          <td class="text-left">
            {{ record.owner.viewName }}
            <div class="nationalid-small text-weight-light">{{ formatNationalId(record.owner) }}</div>
          </td>
          <td class="text-right" colspan="4"></td>
        </tr>
        <tr
          v-for="instrument in getFilteredInstruments(record)"
          :key="`instrument-${i}-${instrument.name}`"
          class="instrument-row"
        >
          <td class="text-left">{{ instrument.name }}</td>
          <td class="text-right">{{ formatNumber(instrument.quantity) }}</td>
          <td class="text-right">{{ formatPercent(instrument.capital / totalCapital) }}</td>
          <td class="text-right">{{ formatNumber(instrument.votes) }}</td>
          <td class="text-right">{{ formatPercent(instrumentVoteShare(record, instrument)) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { ShareRegister } from '@/models';
import { store } from '@shared/web/store';
import type { InstrumentPositionSummary, OwnerRegisterRecord } from '@shared/shareRegister/utils';

@Component
export default class OwnerSummaryPDF extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop({ default: null }) settleDate: string;

  created() {
    this.shareRegister.settleDate = this.settleDate ? new Date(this.settleDate) : new Date();
  }


  instrumentVoteShare(record: OwnerRegisterRecord, instrument: InstrumentPositionSummary): number {
    return (record.voteShare * instrument.votes) / record.votes;
  }

  get owners() {
    return this.shareRegister.getOwnerSummaries().sort((a, b) => b.voteShare - a.voteShare);
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  get totalCapital() {
    return this.shareRegister.getHistoricIssuerData().totalCapital;
  }

  getFilteredInstruments(record: OwnerRegisterRecord) {
    return record.instruments.filter(instrument => instrument.quantity);
  }
}
</script>
<style lang="scss">
.OwnerSummaryPDF {
  position: relative;
  display: flex;
  flex-direction: column;
  page-break-before: always;
  align-items: center;
  h1 {
    font-size: 40px;
    font-weight: 200;
    line-height: 200%;
    color: $primary;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    tbody {
      page-break-inside: avoid;
      page-break-before: auto;
    }
    tr {
      &.owner-row {
        td {
          padding-top: 0.2em;
          border-top: solid 1px $grey-4;
        }
      }
      &.instrument-row {
        td {
          padding-left: 1em;
        }
      }
      th {
        border-bottom: solid 2px $primary;
      }
      td,
      th {
        font-size: 12px !important;
      }
    }
  }
}
</style>
