import { Model } from '../base';
import { Activity, ContactInfo, Entity } from '@shared/web/models';
import { IOwnership } from '@shared/models/modelTypes';
import { OwnershipState } from '@shared/models/types';
export default class Ownership extends Model implements IOwnership {
  owner: Entity;
  investor: Entity;
  custodian: Entity;
  custodianAccountNumber: string;
  insuranceNumber: string;
  startDate: Date = null;
  endDate?: Date = null;
  insuranceFee?: string;
  state: OwnershipState;
  isPartnerClient: boolean;
  partnerContact?: ContactInfo;
  billing?: string;

  static define = () => ({
    owner: {
      model: Entity,
    },
    investor: {
      model: Entity,
    },
    custodian: {
      model: Entity,
    },
    startDate: {
      type: 'date',
    },
    endDate: {
      type: 'date',
    },
    partnerContact: {
      embeddedModel: ContactInfo,
    },
    lastActivity: {
      embeddedModel: Activity,
    },
  });
  static baseUrl = '/api/ownership';

  get viewUrl() {
    return this.id ? '/insurance/view/' + this.id : null;
  }

  get status(): string {
    if (this.isClosed) {
      return 'Stängd';
    } else if (this.isActive) {
      return 'Aktiv';
    } else {
      return 'Ska granskas';
    }
  }

  get isClosed(): boolean {
    return this.state === OwnershipState.CLOSED;
  }

  get isReview(): boolean {
    return this.state === OwnershipState.REVIEW;
  }

  get isActive(): boolean {
    return this.state === OwnershipState.CONFIRMED;
  }
}
