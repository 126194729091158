import * as _ from 'lodash';
export { default as assignDeep } from 'assign-deep';

export function isSameModel(a, b) {
  return asModelId(a) === asModelId(b);
}

export function pickDefined(obj) {
  return _.pickBy(obj, v => v !== undefined);
}
export function stringToBoolean(value) {
  switch (value) {
    case 'true':
    case true:
      return true;
    case 'false':
    case false:
      return false;
    default:
      return null;
  }
}

export const getEnv = (): string => {
  const host = window.location.host;
  const parts = host.split('.');
  const subText =
    parts[0] === 'localhost:8080' || parts[0] === 'localhost:8081'
      ? 'Localhost'
      : parts[0] === 'staging'
      ? 'Stage'
      : parts[0] === 'dev'
      ? 'Dev'
      : '';

  return subText;
};

export function addById(array: Array<any>, element) {
  const existingIndex = _.findIndex(array, o => o.id === element.id);
  if (existingIndex < 0 || element.id == null) {
    array.push(element);
  } else {
    array.splice(existingIndex, 1, element);
  }
}

export function lastElement(array) {
  return array && array[array.length - 1];
}

export function formatPercent(value) {
  if (isNaN(value) || value === null || value === '') {
    return '';
  }
  return new Intl.NumberFormat('sv-SE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'percent',
  }).format(value);
}

export function removeById(array: Array<any>, element) {
  const existingIndex = _.findIndex(array, o => o.id === element.id);
  if (existingIndex >= 0) {
    array.splice(existingIndex, 1);
  }
}

export function remove(array: Array<any>, element) {
  const existingIndex = _.findIndex(array, o => o === element);
  if (existingIndex >= 0) {
    array.splice(existingIndex, 1);
  }
}

export function findById(array, elementId) {
  return _.find(array, o => o.id === elementId);
}

export function assertTrue(expr) {
  if (!expr) {
    throw new Error();
  }
}

export function pickNotNull(obj) {
  return _.pickBy(obj, val => val != null);
}

export function asModelId(val) {
  if (val == null) {
    return null;
  }
  if (_.isString(val)) {
    return val;
  }
  return val.id;
}

export const toNumber = (value, maximumFractionDigits?) => {
  if (value == null) {
    return value;
  }
  const res = value.toString().replace(/\s/g, '').replace(',', '.');
  if (isNaN(res) || value === null || value === '') {
    return value;
  }
  const result = Number(res);
  if (maximumFractionDigits != null) {
    return _.round(result, maximumFractionDigits);
  }
  return result;
};

export const isNumber = value => {
  const res = toNumber(value);
  if (res == null || res === '') {
    return false;
  }
  return !isNaN(res);
};

export function countDecimals(value) {
  if (value == null) {
    return 0;
  }

  const lastPart = value.toString().split('.')[1];

  return (lastPart && lastPart.length) || 0;
}

export const formatNumber = (number, min = 0, max = 2) => {
  if (isNaN(number) || number === null || number === '') {
    return '';
  }

  const decimals = countDecimals(number);
  if (decimals > min) {
    min = _.min([decimals, max]);
  }

  return new Intl.NumberFormat('sv-SE', { minimumFractionDigits: min, maximumFractionDigits: max }).format(number);
};

export type tokenPayload = {
  username: string;
  id: string;
  iat: number;
  exp: number;
};
export const parseJwt = (token: string): tokenPayload => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const looksLikeId = value => /^[0-9a-z]{24}$/.test(value);

export function isToNewPage(to, from) {
  if (to == null) {
    return false;
  }
  return to.matched[0].path !== from.matched[0].path;
}

export async function wait(ms) {
  return new Promise(resolve => {
    setTimeout(() => resolve({}), ms);
  });
}

export function zeroDefault(val: number) {
  return val || 0;
}
