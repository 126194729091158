<template>
  <div class="WarrantDetails">
    <div class="row q-col-gutter-lg">
      <div class="col-12 col-md-2">
        <p class="B1Small">Underliggande aktie</p>
        <a-select
          v-bind="$defs.input"
          v-model="rightsData.underlyingInstrument"
          :options="entity.shares"
          :nullOption="false"
          placeholder="Välj"
          map-options
          option-label="viewName"
          @change="change"
          :error="!!errors.underlyingInstrument"
          :error-message="errors.underlyingInstrument"
          :readonly="readonly"
          :disable="readonly"
        ></a-select>
      </div>
      <div class="col-12 col-md-2">
        <p class="B1Small">Premie</p>
        <a-input-number
          v-bind="$defs.input"
          v-model="rightsData.premium"
          :error="!!errors.premium"
          :error-message="errors.premium"
          :readonly="readonly"
          :disable="readonly"
          @change="change"
          :fractionDigits="2"
          input-class="text-right "
        ></a-input-number>
      </div>
      <div class="col-12 col-md-2">
        <p class="B1Small">Utgivningsår</p>
        <a-select
          v-bind="$defs.input"
          placeholder="yyyy"
          emit-value
          map-options
          class="fill-width"
          v-model="rightsData.issueDate"
          :readonly="readonly"
          :disable="readonly"
          :options="yearOptions"
          :error="!!errors.issueDate"
          :error-message="errors.issueDate"
          @change="change"
        />
      </div>
      <div class="col-12 col-md-4">
        <p class="B1Small">Teckningsperiod</p>
        <div class="exercise-period">
          <a-input-date
            v-bind="$defs.input"
            placeholder="yyyy-mm-dd"
            v-model="rightsData.exercisePeriodFrom"
            :error="!!errors.exercisePeriodFrom"
            :error-message="errors.exercisePeriodFrom"
            @change="change"
            input-class="test-exercise-period-from"
            :readonly="readonly"
            :disable="readonly"
          ></a-input-date>

          <a-input-date
            v-bind="$defs.input"
            placeholder="yyyy-mm-dd"
            v-model="rightsData.exercisePeriodTo"
            :error="!!errors.exercisePeriodTo"
            :error-message="errors.exercisePeriodTo"
            @change="val => change(val, 'setDefaultDueDate')"
            :readonly="readonly"
            :disable="readonly"
            input-class="test-exercise-period-to"
          ></a-input-date>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <p class="B1Small">Slutdag</p>
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          v-model="rightsData.dueDate"
          :error="!!errors.dueDate"
          :error-message="errors.dueDate"
          @change="change"
          :readonly="readonly"
          :disable="readonly"
        ></a-input-date>
      </div>
    </div>
    <div class="row q-col-gutter-lg second-row">
      <div class="col-12 col-md-3">
        <p class="B1Small">Namn på teckningsoption</p>
        <q-input v-bind="$defs.input" v-model="warrantName" :readonly="true" :disable="true" />
      </div>
      <div class="col-12 col-md-2">
        <p class="B1Small">Antal utgivna teckningsoptioner</p>
        <a-input-number
          v-bind="$defs.input"
          v-model="rightsData.totalQuantity"
          :error="!!errors.totalQuantity"
          :error-message="errors.totalQuantity"
          :readonly="readonly"
          :disable="readonly"
          :fractionDigits="0"
          @change="change"
          input-class="text-right "
        ></a-input-number>
      </div>
      <div class="col-12 col-md-1">
        <p class="B1Small">{{ isExerciseWarrant ? 'Nyttjade TO' : 'Tecknade' }}</p>
        <a-input-number
          v-bind="$defs.input"
          :modelValue="totalQuantity"
          :readonly="true"
          :disable="true"
          :input-class="'text-right validation' + (validQuantity ? ' valid' : '')"
        ></a-input-number>
      </div>
      <div class="col-12 col-md-2">
        <p class="B1Small">Antal underliggande per option</p>
        <a-input-number
          v-bind="$defs.input"
          v-model="rightsData.contractSize"
          :error="!!errors.contractSize"
          :error-message="errors.contractSize"
          :readonly="!contractSizeIsEditable"
          :disable="!contractSizeIsEditable"
          :maximumFractionDigits="6"
          @change="change"
          input-class="text-right "
        ></a-input-number>
      </div>
      <div class="col-12 col-md-2">
        <p class="B1Small">Totalt antal vid full teckning</p>
        <a-input-number
          v-bind="$defs.input"
          :modelValue="rightsData.shareQuantity"
          :readonly="true"
          :disable="true"
          @change="change"
          input-class="text-right "
        ></a-input-number>
      </div>
      <div class="col-12 col-md-2">
        <p class="B1Small">Teckningskurs</p>
        <a-input-number
          v-bind="$defs.input"
          v-model="rightsData.exercisePrice"
          :error="!!errors.exercisePrice"
          :error-message="errors.exercisePrice"
          :readonly="readonly"
          :disable="readonly"
          @change="change"
          :maximumFractionDigits="6"
          :minimumFractionDigits="2"
          input-class="text-right "
        ></a-input-number>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Entity, Instruction, ShareRegister, TransactionType } from '@/models';
import { createWarrantName } from '@shared/rightsIssue/utils';
import * as _ from 'lodash';

@Component({
  inheritAttrs: false,
})
export default class WarrantDetails extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() entity: Entity;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop() validator;
  @Prop({ default: () => ({}) }) errors;
  @Prop({ default: false }) splitHappened;
  change(val, action) {
    if (action === 'setDefaultDueDate') {
      if (!this.rightsData.dueDate) {
        this.rightsData.dueDate = val;
      }
    }
    this.$emit('change');
  }

  get isExerciseWarrant() {
    return this.instruction.type === TransactionType.EXERCISE_WARRANT;
  }

  get rightsData() {
    return this.instruction.corporateEvent.rightsData;
  }

  get contractSizeIsEditable() {
    if (!this.readonly) {
      return true;
    }
    return this.isExerciseWarrant && this.instruction.isEditable && this.splitHappened;
  }

  get yearOptions() {
    const baseYear = this.rightsData.issueDate?.getFullYear() || new Date().getFullYear();
    const range = _.range(baseYear - 5, baseYear + 5);
    return range.map(year => ({
      label: `${year}`,
      value: new Date(`${year}-01-01T00:00:00.000Z`),
    }));
  }

  get subscribedShares() {
    return this.rightsData.subscribedShares;
  }

  get totalQuantity() {
    return (this.rightsData.subscribedShares = this.instruction.calculateSubscribedShares());
  }

  get validQuantity() {
    if (this.isExerciseWarrant) {
      return this.rightsData.shareQuantity === this.subscribedShares;
    }
    return this.rightsData.totalQuantity === this.subscribedShares;
  }
  get warrantName() {
    return createWarrantName(this.rightsData, this.shareRegister.instruments);
  }
}
</script>
<style lang="scss">
.WarrantDetails {
  margin-bottom: -22px;
  .text-grey-6 {
    opacity: 0.65;
  }
  .exercise-period {
    display: flex;

    .q-input + .q-input {
      margin-left: 10px;
    }
  }

  .second-row {
    margin-top: 8px;
    .B1Small {
      height: 30px;
    }
  }
}
</style>
