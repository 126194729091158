<template>
  <q-dialog class ref="dialog">
    <div class="FullUserConditionsModal">
      <q-btn size="small" v-bind="$defs.btn" flat @click="closeModal" label="Stäng" />
      <section>
        <h5 class="uppercase">Kapnet – ALLMÄNnA VILLKOR för Investerare</h5>
      </section>
      <section>
        Dessa avtalsvillkor (”<b>Allmänna Villkor</b>”) gäller mellan Kapclear AB, org.nr 559278-3574, med adress C/o
        iOffice, Vasagatan 12, 111 20 Stockholm, (<b>"Kapclear"</b>, <b>”Vi”</b> eller <b>”Oss”</b>) och dig, eller det
        bolag Du företräder (”Investeraren”) och som använder Kapclears tjänst Kapnet. Genom att Investeraren använder
        Kapnet eller loggar in i Kapnet via BankID accepterar Investeraren dessa Allmänna Villkor och personen som utför
        dessa åtgärder garanterar att denne har rätt att företräda Investeraren samt rätt att godkänna dessa Allmänna
        Villkor. Är Investeraren en fysisk person garanterar Investeraren även att Investeraren är minst 18 år.
      </section>

      <section>
        <section class="section-gap">
          <p class="section-child bold">1</p>
          <p class="section-child bold uppercase">om kapnet</p>
        </section>
      </section>

      <section>
        <section class="section-gap">
          <p class="section-child">1.1</p>
          <p class="section-child">
            Kapnet är Kapclears investerarnätverk. Genom Kapnet får Investerare möjlighet att ta del av
            investeringserbjudanden som marknadsförs av onoterade bolag (”<b>Emittent</b>” eller ”<b>Emittenter</b>”).
            Samtliga erbjudanden om investeringar som marknadsförs av Emittenter via Kapnet benämns i dessa Allmänna
            Villkor för ”<b>Investeringserbjudande</b>” eller ”<b>Investeringserbjudanden</b>”.
          </p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child">1.2</p>
        <p class="section-child">
          Kapnet utgör en marknadsföringsplats för Emittenter att marknadsföra Investeringserbjudanden till Investerare.
          Samtliga överenskommelser till följd av ett Investeringserbjudanden som lämnats via Kapnet ingås direkt mellan
          Investeraren och Emittenten. Kapclear är inte i något fall part i avtal mellan Emittent och Investerare, eller
          på annat sätt att anse som delaktig i de Investeringserbjudanden som marknadsförs av Emittenter via Kapnet,
          utan fungerar endast som förmedlare av kontakt mellan Emittenter och Investerare.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">1.3</p>
        <p class="section-child">
          Kapclear företräder varken Investerare eller Emittenter och gör ingen bedömning av Investerare, varken i fråga
          om betalningsförmåga eller i andra avseenden. Kapclear ger inga rekommendationer eller utlåtande till
          Investerare eller annan part avseende de Emmittenter som är registrerade på Kapnet. Kapclear ansvarar inte för
          riktigheten i information som lämnas av vare sig Investerare, Emittent eller annan tredje part via Kapnet
          eller i andra former och kanaler.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">1.4</p>
        <p class="section-child">
          Kapnet tillhandahålls i befintligt skick. Investerarens användning av Kapnet sker under eget ansvar och på
          egen risk. Kapclear lämnar inte heller några garantier eller utfästelser beträffande Kapnet eller dess
          funktion, tillgänglighet, kvalitet, användbarhet eller säkerhet.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child bold">2</p>
        <p class="section-child bold uppercase">Användarkonto</p>
      </section>

      <section class="section-gap">
        <p class="section-child">2.1</p>
        <p class="section-child">
          För att använda Kapnet måste Investeraren skapa ett användarkonto (”<b>Användarkonto</b>") genom legitimering
          via BankID.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">2.2</p>
        <p class="section-child">
          Det är inte tillåtet att låta någon annan använda Kapnet genom Användarkontot och Investeraren är ansvarig för
          att säkerställa att Investerarens BankID inte nyttjas för att bereda åtkomst till Kapnet för annan än
          Investeraren. Om Investeraren, oavsett orsak,misstänker att Användarkontot används obehörigen ska Investeraren
          omedelbart meddela Kapnet om detta.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child bold">3</p>
        <p class="section-child bold uppercase">användning av Kapnet</p>
      </section>

      <section class="section-gap">
        <p class="section-child">3.1</p>
        <p class="section-child">
          Investeraren ges en icke-exklusiv, icke-överlåtbar, återkallelig rätt att använda Kapnet i Sverige, i syfte
          att nyttja Kapnet för dess avsedda syfte. Investeraren erhåller ingen annan rätt att använda Kapnet utom vad
          som uttryckligen anges i dessa Allmänna Villkor.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">3.2</p>
        <p class="section-child">
          Innehåll som publiceras i Kapnet kan tillhöra tredje parter och Kapclear har inte kontroll över sådant
          innehåll. Kapclear kan inte kan hållas ansvarig för sådant innehåll som tillhandahålls av tredje part och
          visas upp eller finns tillgängligt inom ramen för Kapnet.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">3.3</p>
        <p class="section-child">
          Investeraren är förbjuden att helt eller delvis, kopiera eller förändra Kapnet. Det är inte tillåtet för
          Investeraren eller annan att utveckla, göra tillägg till, dekompilera eller göra reverse engineering av
          Kapnet. Det är inte tillåtet att återskapa källkoden eller dess funktionalitet, eller att göra kopior av
          mjukvaran, utöver vad tvingande lag ger rätt till.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">3.4</p>
        <p class="section-child">
          Kapnet får inte användas för att sprida virus, trojaner eller liknande program. Automatisk avläsning av Kapnet
          är inte tillåtet.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">3.5</p>
        <p class="section-child">
          Alla immateriella rättigheter i Kapnet, tillhör eller förfogas över med licens från Kapclear. Ingenting i
          dessa Allmänna Villkor ska uppfattas som en överföring av någon immateriell eller annan rättighet till
          Investeraren eller annan.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">3.6</p>
        <p class="section-child">
          Investeraren ska löpande förse Kapclear med den information och data som efterfrågas samt för att Kapclear ska
          kunna tillhandahålla Kapnet till Investeraren.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">3.7</p>
        <section class="section-gap-column">
          <p class="section-child">
            Investeraren ansvarar ensam för data, information, uppgifter, text, meddelanden och annat material som
            Investeraren tillhandahåller Kapnet. Investeraren accepterar och garanterar att Investeraren inte kommer
            ladda upp eller sprida information som:
          </p>
          <div>
            <section class="section-gap indented-section">
              <p class="section-child">(i)</p>
              <p class="section-child">
                är oriktigt, vilseledande, osant eller inkorrekt och hålls uppdaterade vid behov,
              </p>
            </section>
            <section class="section-gap indented-section">
              <p class="section-child">(ii)</p>
              <p class="section-child">främjar eller uppmuntrar illegal aktivitet,</p>
            </section>
            <section class="section-gap indented-section">
              <p class="section-child">(iii)</p>
              <p class="section-child">
                är rasistiskt eller etniskt kränkande och/eller utgör agitation mot en minoritet (så som en nationell
                eller etnisk grupp),
              </p>
            </section>
            <section class="section-gap indented-section">
              <p class="section-child">(iv)</p>
              <p class="section-child">
                utgör ärekränkning, innehåller pornografi eller är på annat sätt sexuellt kränkande,
              </p>
            </section>
            <section class="section-gap indented-section">
              <p class="section-child">(v)</p>
              <p class="section-child">
                utgör angrepp på sexuell läggning eller religion eller är diskriminerande på annat sätt,
              </p>
            </section>
            <section class="section-gap indented-section">
              <p class="section-child">(v)</p>
              <p class="section-child">
                utgör angrepp på sexuell läggning eller religion eller är diskriminerande på annat sätt,
              </p>
            </section>
            <section class="section-gap indented-section">
              <p class="section-child">(vi)</p>
              <p class="section-child">
                utgör angrepp på sexuell läggning eller religion eller är diskriminerande på annat sätt,
              </p>
            </section>
          </div>
        </section>
      </section>
      <section class="section-gap">
        <p class="section-child">3.8</p>
        <p class="section-child">
          Investeraren ger Kapclear en licens att i hela världen, lagra, kopiera, överföra och visa all data,
          information, uppgifter, text, meddelanden och annat material som Investeraren tillhandahåller till Kapnet, för
          att kunna tillhandahålla Kapnet i enlighet med dessa Allmänna Villkor.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child bold">4</p>
        <p class="section-child bold uppercase">Investeringserbjudande</p>
      </section>

      <section class="section-gap">
        <p class="section-child">4.1</p>
        <p class="section-child">
          Investerare får tillgång till Investeringserbjudanden från Emmitenter via Kapnet, eller via den e-postadress
          eller det telefonnummer som är registrerats till Användarkontot. Vill Investeraren inte längre få del av
          Investeringserbjudanden från Kapclear via e-post kan Investeraren när som helst avregistrera sig från framtida
          utskick från Kapclear i enlighet med de instruktioner för avregistrering som framgår av relevanta
          e-postmeddelanden.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">4.2</p>
        <p class="section-child">
          Investerare har möjlighet att anmäla intresse till Investeringserbjudanden som läggs upp i Kapnet. Sådan
          intresseanmälan är icke-bindande. Har Investeraren gjort en intresseanmälan har Emittenter rätt, men ingen
          skyldighet, att lämna ett slutligt investeringserbjudande till Investeraren. Villkoren för det slutliga
          investeringserbjudandet fastställs av Emittenten.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">4.2</p>
        <p class="section-child">
          Investerare har möjlighet att anmäla intresse till Investeringserbjudanden som läggs upp i Kapnet. Sådan
          intresseanmälan är icke-bindande. Har Investeraren gjort en intresseanmälan har Emittenter rätt, men ingen
          skyldighet, att lämna ett slutligt investeringserbjudande till Investeraren. Villkoren för det slutliga
          investeringserbjudandet fastställs av Emittenten.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">4.3</p>
        <p class="section-child">
          Bindande överenskommelse om en investering som marknadsförts genom ett Investeringserbjudanden ingås direkt
          mellan Investeraren och Bolaget i enlighet med civilrättsliga bestämmelser för ingående av den typen av avtal
          det är frågan om. Fram tills att ett bindande avtal föreligger mellan Investeraren och Bolaget kan en
          investering för vilken Investeraren lämnat en intresseanmälan via Kapnet avbrytas av Emittenten eller
          Investeraren.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">4.4</p>
        <p class="section-child">
          Betalning till följd av en investering som har förmedlats via Kapnet sker direkt mellan Emittent och
          Investerare enligt de villkor som Emittent och Investerare kommit överens om. Kapclear tar inget ansvar för,
          och är inte inblandat i, sådana betalningar.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">4.5</p>
        <p class="section-child">
          Investeraren garanterar genom accepterandet av dessa Allmänna Villkor att endast genomföra investering och
          ingå avtal med Emittent för egen räkning.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child bold">5</p>
        <p class="section-child bold uppercase">Regler för investeringserbjudanden</p>
      </section>

      <section class="section-gap">
        <p class="section-child">5.1</p>
        <p class="section-child">
          När ett Investeringserbjudande läggs upp i Kapnet är Emittenten ansvarig för att den information som
          publiceras i Kapnet är korrekt, aktuell, fullständig och inte strider mot gällande lagar eller regler,
          inklusive men inte begränsat till de krav som följer av marknadsföringslagen (2008:486) och god
          marknadsföringssed, samt följer de regler för Investeringserbjudanden som överenskommits mellan Kapclear och
          Emittenten.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">5.2</p>
        <p class="section-child">
          Kapclear godkänner inte och granskar inte, varken från perspektivet av laglighet eller annars, de
          Investeringserbjudanden som läggs upp i Kapnet. Kapclear står inte heller bakom Investeringserbjudanden och
          tar inget ansvar för Investeringsarbjudanden, varken dess utformning eller genomförande, utan bara
          tillhandahåller förmedlingstjänsten Kapnet.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">5.3</p>
        <p class="section-child">
          Kapclear lämnar ingen rådgivning till Investerare eller Emittenter i fråga om eventuell investering till följd
          av ett Investeringserbjudande, varken juridisk, skattemässig, redovisningsmässig, ekonomisk eller i något
          annat avseende.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">5.4</p>
        <p class="section-child">
          Investeraren är ensamt är ansvarig för att utvärdera ett Investeringserbjudande innan intresseanmälan och
          sedermera eventuell teckning och betalning görs. Investerare bör även rådgöra med ekonomiska och juridiska
          rådgivare innan beslut om investering fattas till följd av ett Investeringserbjudande. Då samtliga
          Investeringserbjudanden som marknadsförs av Bolag på Kapnet avser onoterade värdepapper bör Investeraren vara
          särskilt medveten om att det kan finnas begränsade möjligheter för Dig att avyttra förvärvade värdepapper.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child bold">6</p>
        <p class="section-child bold uppercase">SkattskylDighet</p>
      </section>

      <section class="section-gap">
        <p class="section-child">6.1</p>
        <p class="section-child">
          Investeraren garanterar att Investeraren är folkbokförd (om Investeraren är en fysisk person) och är
          obegränsat skattskyldig inom det Europeiska ekonomiska samarbetsområdet (”<b>EES</b>”) under hela den tid som
          Investeraren använder Kapnet.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">6.2</p>
        <p class="section-child">
          Inga Investeringserbjudanden får rikta sig, direkt eller indirekt, till fysiska eller juridiska personer som
          är bosatta, folkbokförda och/eller skattskyldiga utanför EES.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">6.3</p>
        <p class="section-child">
          Inga investeringar har registrerats eller kommer att registreras enligt United States Securities Act från 1933
          eller enligt någon motsvarande lag i någon delstat i USA, eller enligt tillämplig lag i annat land.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">6.4</p>
        <p class="section-child">
          Inga Investeringserbjudanden som marknadsförs via Kapnet riktar sig, varken direkt eller indirekt, till annat
          land där distribution av investeringen eller erbjudandet om investeringen strider mot tillämpliga lagar eller
          regler eller som förutsätter andra åtgärder såsom t.ex. prospekt, registreringar än de krav som följer av
          svensk rätt. Inga Investeringserbjudanden som marknadsförs via Kapnet riktar sig heller, varken direkt eller
          indirekt, till sådana personer vars deltagande av annan anledning förutsätter andra åtgärder vidtas (såsom
          t.ex. upprättande av prospekt eller registrering) än vad som följer av svensk rätt. Varken
          Investeringserbjudande, intresseanmälan till följd av ett Investeringserbjudanden eller annan information som
          återfinns via Kapnet får distribueras i eller till land där distributionen eller Investeringserbjudande
          förutsätter registreringsåtgärder eller andra åtgärder än sådana som följer av svensk rätt eller strider mot
          tillämpliga bestämmelser i sådant land. Det åligger Investeraren att iaktta sådana begränsningar enligt lagar
          och regler utanför Sverige.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child bold">7</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">Priser och betalning</p>
          <p class="section-child">Investerares använding av Kapnet är kostnadsfri i förhållande till Kapclear.</p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child bold">8</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">Konfidentialitet</p>
          <p class="section-child">
            Investeraren åtar sig att behandla all information som Investeraren erhåller via Kapnet, inklusive men inte
            begränsat till information som lämnats via ett Investeringserbjudanden, konfidentiellt. Det innebär att
            Investeraren åtar sig att inte sprida, vidarebefordra eller på annat sätt delge information som Investeraren
            får del av via Kapnet till tredje man eller att nyttja sådan information för annat syfte än utvärdering av
            och genomförande av investering till följd av ett Investeringserbjudande.
          </p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child bold">9</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">tillgänglighet</p>
          <p class="section-child">
            Kapclear lämnar inga garantier om Kapnets tillgänglighet. Kapnet kan således ligga nere under obestämd tid
            till följd av t.ex. tekniska problem, planerade eller oplanerade underhållsåtgärder, uppdateringar eller
            andra omständigheter.
          </p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child bold">10</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">Nätverkskostnader</p>
          <p class="section-child">
            Kapnet är kompatibel med samtliga standardwebbläsare. Investeraren är själv ansvarig för att säkerställa din
            åtkomst till det nätverk som behövs för att kunna använda Kapnet. Det kan tillkomma kostnader för t.ex.
            överföring av data och meddelandetjänster. Dessa kostnader står inte Kapclear för. Investeraren är själv
            ansvarig för att skaffa och uppdatera den hård- eller mjukvara som behövs för att få tillgång till Kapnet.
          </p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child bold">11</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">Avtalstid och uppsägning av avtalet</p>
          <p class="section-child">
            Dessa Allmänna Villkor träder i kraft på dagen för Investerarens registrering av Användarkonto i Kapnet och
            fortsätter gälla tills det sägs upp av någon av parterna genom skriftligt meddelande till den andra parten,
            eller genom att Investeraren eller Kapclear avslutar Användarkonto.
          </p>
        </section>
      </section>

      <section>
        <section class="section-gap">
          <p class="section-child bold">12</p>
          <p class="section-child bold uppercase">Ansvarsbegräsning För Kapclear</p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child">12.1</p>
        <p class="section-child">
          Kapclear ansvarar inte för skada, vare sig direkt eller indirekt, som Investerare kan drabbas av med
          användningen av Kapnet, inklusive, men inte begränsat till, skada (inklusive, men ej begränsat till, utebliven
          vinst, inkomstförlust, minskad omsättning, förlust eller stöld av data, avbrott i verksamheten eller
          goodwillförluster) till följd av genomförd eller utebliven investering till exempel på grund av att (i) Kapnet
          inte varit tillgängligt, (ii) Emittenter inte uppfyllt sina förpliktelser och/eller följt relevanta avtal,
          föreskrifter, lagar eller regler, (iii) åtgärder och/eller beslut med anledning av att en investering ej
          vidtagits och/eller vidtagits vid fel tidpunkt eller i fel omfattning, eller (iv) Emittenten lämnat felaktiga
          eller vilseledande uppgifter.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">12.2</p>
        <p class="section-child">
          Kapclear har rätt att stänga av Investerarens tillgång till Kapnet med omedelbar verkan om Kapclear bedömer
          att Investeraren bryter mot dessa Allmänna Villkor. Kapclear har vidare rätt att, efter eget godtycke, när som
          helst modifiera, avbryta eller temporärt eller permanent upphöra med tillhandahållandet av Kapnet utan
          föregående meddelande härom. Investeraren accepterar att Kapclear inte ansvarar för sådan modifikation,
          avbrott eller upphörande.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">12.3</p>
        <p class="section-child">
          Investeraren accepterar att krav i anledning av Investerarens användning av Kapnet, också eventuella krav på
          ersättning Investeraren tvingats utge till följd av Investerarens användning av Kapnet, inte kan riktas mot
          Kapclear eller dess koncernbolag, intressebolag, företrädare eller ägare.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">12.4</p>
        <p class="section-child">
          Ansvarsbegräsningen i detta avsnitt ändrar eller inskränker inte rättigheter som gäller i den utsträckning
          Investeraren anses vara konsument och utesluter eller begränsar inte ansvar i sådan utsträckning som inte är
          tillåtet enligt tillämplig lag.
        </p>
      </section>

      <section>
        <section class="section-gap">
          <p class="section-child bold">13</p>
          <p class="section-child bold uppercase">Investerarens Ansvar</p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child">13.1</p>
        <p class="section-child">
          Investeraren är ansvarig gentemot Kapclear för all skada som Investeraren kan orsaka på grund av brott mot
          dessa Allmänna Villkor.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">13.2</p>
        <p class="section-child">
          Om anspråk i någon form skulle riktas mot Kapclear från någon som inte är part till detta avtal och detta
          beror på Investerarens försumlighet ska Investeraren ersätta Kapclear för all den direkta eller indirekta
          skada som Kapclear kan ha åsamkats samt ersätta Kapclear för arbete och kostnader, inklusive
          rättegångskostnader och advokatarvoden i anledning av mot Kapclear framförda anspråk. För det fall Investerare
          delar information eller annat material via Kapnet som gör intrång i tredje parts immateriella rättighet åtar
          sig Investerare att omedelbart ta bort samtliga intrångsgörande delar av materialet och hålla Kapclear
          skadeslösa för samtliga skador, kostnader och utlägg Kapclear ådragit oss som resultat av sådant intrång.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child bold">14</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">Personuppgiftsbehandling</p>
          <p class="section-child">
            Kapclear behandlar personuppgifter som vi erhåller i samband med användandet av Kapnet. Personuppgifterna
            behandlas i enlighet med vår integritetspolicy, tillgänglig här
            <a href="https://kaptena.se/cookies-integritet-och-sekretess/" target="_blank" rel="noreferrer"
              >www.kaptena.se/cookies-integritet-och-sekretess</a
            >. Vänligen läs denna integritetspolicy innan Du börjar använda Kapnet och använd inte Kapnet om Du inte
            accepterar vår integritetspolicy.
          </p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child bold">15</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">ÄNDRING AV VILLKOREN och ÄNDRING AV Kapnet</p>
          <p class="section-child">
            Kapclear har rätt att ändra dessa Allmänna Villkor och Investeraren kommer att meddelas om ändringarna genom
            publicering på hemsidan eller via e-post. De ändrade villkoren blir bindande för Investeraren vid den dag
            Investeraren accepterar dem genom fortsatt användning av Kapnet efter meddelande enligt det föregående.
          </p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child bold">16</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">Ogiltighet</p>
          <p class="section-child">
            Skulle någon bestämmelse i dessa Allmänna Villkor eller del därav befinnas ogiltig ska detta inte innebära
            att dessa Allmänna Villkor i dess helhet är ogiltigt. I stället ska skälig jämkning av dessa Allmänna
            Villkor ske.
          </p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child bold">17</p>
        <section class="section-gap-column">
          <p class="section-child bold uppercase">överlåtelse</p>
          <p class="section-child">
            Investeraren får inte överlåta eller överföra några rättigheter, skyldigheter eller licenser som framgår av
            dessa Allmänna Villkor. Kapclear kan överlåta och överföra sina rättigheter enligt dessa Allmänna Villkor
            utan Investerarens samtycke och utan meddelande till Investeraren.
          </p>
        </section>
      </section>

      <section>
        <section class="section-gap">
          <p class="section-child bold">18</p>
          <p class="section-child bold uppercase">TILLÄMPLIG LAG OCH TVISTER</p>
        </section>
      </section>

      <section class="section-gap">
        <p class="section-child">18.1</p>
        <p class="section-child">
          Dessa Allmänna Villkor ska styras av och tolkas i enlighet med svensk lag, utan tillämpning av lagvalsregler.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child">18.2</p>
        <p class="section-child">
          Tvist eller krav som uppstår rörande eller i samband med dessa Användarvillkor, eller vid brott, uppsägning
          eller ogiltighet av dessa ska slutligen avgöras av svensk domstol, med Stockholms tingsrätt som första
          instans, om inte annat följer av tvingande lag.
        </p>
      </section>

      <section class="section-gap">
        <p class="section-child bold">19</p>
        <section class="section-column">
          <p class="section-child bold uppercase">Kontaktuppgifter:</p>
          <p class="section-child">Kapclear AB</p>
          <p class="section-child">C/o iOffice</p>
          <p class="section-child">Vasagatan 12</p>
          <p class="section-child">111 20 Stockholm</p>
          <p class="section-child">Org.nr: 559278-3574</p>
          <p class="section-child">Org.nr: 559278-3574</p>
          <a href="https://www.kaptena.se" target="_blank" rel="noreferrer">www.kaptena.se</a>
          <div><span>Epost:</span><a href="mailto:info@kaptena.se">info@kaptena.se</a></div>
          <p class="section-child">Tel: 08 409 100 80</p>
        </section>
      </section>
      <q-btn class size="small" v-bind="$defs.btn" flat @click="closeModal" label="Stäng" />
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component({})
export default class FullUserConditionsModal extends Vue {
  closeModal() {
    this.$emit('hide');
  }
}
</script>
<style lang="scss">
.FullUserConditionsModal {
  display: grid;
  gap: 0.5rem;
  background: white;
  padding: 4rem;
  min-width: 42rem;

  .section-gap {
    display: flex;
    gap: 0.5rem;
  }

  .section-gap-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .section-column {
    display: flex;
    flex-direction: column;
  }

  .section-child {
    min-width: 1.5rem;
    margin: 0;
  }

  .bold {
    font-weight: bold;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .indented-section {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
}
</style>
