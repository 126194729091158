export enum Layout {
  PUBLIC = 'public',
  ONBOARDING = 'onboarding',
  PDF = 'pdf',
}

export enum Intent {
  LOGIN = 'intent/login',
  REGISTER = 'intent/register',
}

export enum Context {
  USER = 'context/user',
}

export enum LogoRoutes {
  DASHBOARD = '/valjbolag',
  KAPNET = '/investment-offer',
  HOLDINGS = '/mina-innehav',
  SETTINGS = '/mina-inst%C3%A4llningar',
}

export enum PathLogos {
  KAPTENA = 'img:/Kaptena-Vit-slogan.svg',
  KAPNET = 'img:/Kapnet-Vit-slogan.svg',
  KAPCLEAR = 'img:/Kapclear-Vit-slogan.svg',
}
