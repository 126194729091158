<template>
  <div class="RolesTable">
    <h4 v-if="showHeader" class="table-header">Styrelse, firmatecknare, VD - Information från bolagsverket</h4>
    <q-markup-table flat class="standard-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Roller</th>
          <th class="text-left">Namn</th>
          <th class="text-left">Personnummer</th>
          <th class="text-left">E-post</th>
          <th class="text-left">Telefon</th>
          <th v-if="canEdit"></th>
        </tr>
      </thead>
      <tbody class="two-row" v-if="ready">
        <tr :key="id" v-for="{ id, person, types } in rolesGrouped">
          <td class="text-left">
            <template v-for="type in types" :key="type"> {{ toRoleTypeLabel(type) }} <br /> </template>
          </td>
          <td class="text-left">
            <RouterLink v-if="isBackOffice" :to="person.viewUrl">{{ person.viewName }}</RouterLink>
            <span v-else>{{ person.viewName }}</span>
          </td>
          <td class="text-left">
            {{ formatNationalId(person) }}
          </td>
          <td class="text-left">{{ getContactInfo(person).email }}</td>
          <td class="text-left">{{ getContactInfo(person).phone }}</td>
          <td v-if="canEdit">
            <q-btn flat round color="grey-4" icon="more_vert" size="sm">
              <q-menu self="center left" anchor="center left" :offset="[70, 0]" class="a-tooltip shadow-1">
                <q-list>
                  <q-item clickable v-close-popup @click="editRole(person)">
                    <q-item-section>Ändra</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </td>
        </tr>
        <a-table-row-btn v-if="showAddRoleButton" @click="addRole" label="Lägg till Roll" />
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { highestRole, roleTypesOrder, toRoleTypeLabel } from '@shared/common';
import EditRoleModal from '@shared/components/EditRoleModal.vue';
import { sortBy, uniqBy } from 'lodash';
import { RoleType } from '@shared/models/types';
import { findIssuerInfo } from '@shared/services/issuerInfoService';
import { Entity, IssuerInfo } from '@shared/web/models';
import { store } from '@shared/web/store';
import { isBackOffice } from '@shared/web/utils';
import AddRoleModal from '@shared/components/AddRoleModal.vue';
import { loadEntity } from '@shared/web/entity/entityService';

@Component({
  components: { EditRoleModal, AddRoleModal },
})
export default class RolesTable extends Vue {
  @Prop() entity;
  @Prop({ default: true }) showHeader;

  issuerInfo: IssuerInfo = null;

  mounted() {
    this.fetchIssuerInfo();
  }

  async fetchIssuerInfo() {
    if (this.entity.id) {
      this.issuerInfo = await findIssuerInfo(this.entity);
    } else {
      this.issuerInfo = IssuerInfo.newModel({});
    }
  }

  getContactInfo(person) {
    const contactInfo = this.issuerInfo.getRoleInfo(person);
    return contactInfo;
  }

  get isBackOffice() {
    return isBackOffice();
  }

  get showAddRoleButton() {
    return this.isBackOffice && !this.entity.roaringData;
  }

  get ready() {
    return !!this.issuerInfo;
  }

  get roles() {
    const allRoles = [];
    if (this.entity.legalEntity?.roles) {
      allRoles.push(...this.entity.legalEntity.roles);
    }
    if (this.entity.legalEntity?.investorManager) {
      allRoles.push({
        type: RoleType.InvestorManager,
        person: this.entity.legalEntity?.investorManager,
        edit: true,
      });
    }
    return allRoles;
  }

  get rolesGrouped() {
    return sortBy(
      uniqBy(this.roles, rolePerson => rolePerson.person.person.id).map(rolePerson => {
        const personRoles = this.getPersonRoles(rolePerson.person.person.id);
        const personHighestRole = highestRole(personRoles);
        return {
          ...rolePerson,
          types: personRoles,
          highestRole: personHighestRole,
          highestRoleIndex: roleTypesOrder.indexOf(personHighestRole),
        };
      }),
      'highestRoleIndex',
    );
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false || isBackOffice();
  }

  getPersonRoles(personId: string): RoleType[] {
    return sortBy(
      this.roles.filter(rolePerson => rolePerson.person.person.id === personId).map(rolePerson => rolePerson.type),
      item => roleTypesOrder.indexOf(item),
    );
  }

  toRoleTypeLabel(role: RoleType) {
    return toRoleTypeLabel(role);
  }

  editRole(person: Entity) {
    this.$q
      .dialog({
        componentProps: {
          entity: this.entity,
          person,
          roles: this.getPersonRoles(person.person.id),
        },
        component: EditRoleModal,
      })
      .onOk(() => this.fetchIssuerInfo());
  }

  addRole() {
    this.$q
      .dialog({
        componentProps: {
          entity: this.entity,
        },
        component: AddRoleModal,
      })
      .onOk(async () => {
        const entity = await loadEntity(this.entity.id);
        Object.assign(this, entity);
      });
  }
}
</script>
<style lang="scss">
.RolesTable {
  td a {
    font-weight: 400;
  }
}
</style>
