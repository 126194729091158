<template>
  <q-dialog ref="dialog">
    <div class="q-dialog-plugin AddRoleModal" @hide="hide">
      <section class="info">
        <FindEntity class="col" v-model="person" v-bind="$defs.input" :entityOptions="ownersOrInvestors" />
        <a-list-item label="Roller">
          <q-select v-bind="$defs.input" v-model="role" :options="roleOptions" />
        </a-list-item>
      </section>
      <section class="buttons">
        <q-btn v-bind="$defs.btn" size="md" @click="hide" flat>Stäng</q-btn>
        <q-btn v-bind="$defs.btn" size="md" class="saveBtn" @click="saveChanges"> Spara</q-btn>
      </section>
    </div>
  </q-dialog>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';
import { Entity, IssuerInfo, Role } from '@shared/web/models';
import { findIssuerInfo } from '@shared/services/issuerInfoService';
import FindEntity from '@shared/views/editPositionModal/FindEntity.vue';
import { InstrumentCategory } from '@shared/web/models';
import { Products } from '@shared/index';
import { findShareRegister } from '@shared/web/models/shareRegister/shareRegisterService';
import { uniq } from 'lodash';
import { roleTypeLabels, labelToRoleType } from '@shared/common';
import { updateLegalEntityRoles } from '@shared/web/entity/entityService';
@Component({
  components: { FindEntity },
  mixins: [DialogComponent],
  emits: ['ok'],
})
export default class AddRoleModal extends Vue {
  @Prop() entity: Entity;
  issuerInfo: IssuerInfo = null;
  person: Entity = null;
  ownersOrInvestors: Entity[] = null;
  role: string = null;

  async mounted() {
    this.issuerInfo = await findIssuerInfo(this.entity);
    const shareRegister = await findShareRegister({
      entity: this.entity,
      instrumentCategory: InstrumentCategory.SHA,
      instrumentProduct: Products.KAPCLEAR,
      onlyPreliminary: !this.entity.issuerData?.locked,
    });
    const { positions } = shareRegister;
    this.ownersOrInvestors = uniq(positions.map(position => (position.investor ? position.investor : position.owner)));
  }

  get roleOptions() {
    return Object.values(roleTypeLabels).filter(val => val !== 'Investeringsansvarig');
  }

  async saveChanges() {
    const role: Role = {
      type: labelToRoleType[this.role],
      person: this.person,
    };

    if (!this.person || !this.role) {
      throw { text: 'Person och roll måste anges' };
    } else {
      await updateLegalEntityRoles({ entityId: this.entity.id, role });
      this.$emit('ok');
      this.hide();
    }
  }
}
</script>
<style lang="scss">
.AddRoleModal {
  display: flex;
  flex-direction: column;
  .info {
    display: flex;
    flex-direction: column;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
